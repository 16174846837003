import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import SignupCard from '../../components/signup/SignupCard';

/**
 * Main component for the /singup page. Renders a card with components to enter personal data.
 * Expects the component to be wrapped with a react router.
 */
export default function SignupPage() {
  useTitle('PPAM | Crear una nueva cuenta');

  return (
    <div className="max-w-md space-y-8 p-2 md:p-0">
      <motion.div
        className="space-y-8"
        initial={{ rotateY: 90 }}
        animate={{ rotateY: 0 }}
        transition={{ timing: 5 }}
      >
        <SignupCard />
        <div className="w-full text-center">
          <Link to="/login" className="inline-block p-2 text-purple-200">
            ¿Ya tiene una cuenta? <u>Iniciar sesi&oacute;n</u>
          </Link>
        </div>
      </motion.div>
    </div>
  );
}
