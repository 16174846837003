import { motion } from 'framer-motion';
import { useTitle } from 'react-use';
import SelectProviderSection from './SelectProviderSection';
import SignupLinkSection from './SignupLinkSection';

/**
 * Main component for the /login page. Renders a card with components to login.
 * Expects the component to be wrapped with a react router.
 */
export default function LoginPage() {
  useTitle('PPAM | Iniciar sesión');

  return (
    <div className="max-w-md space-y-8 p-2 md:p-0">
      <motion.div
        className="space-y-8"
        initial={{ rotateY: 90 }}
        animate={{ rotateY: 0 }}
        transition={{ timing: 5 }}
      >
        <SelectProviderSection />
        <SignupLinkSection />
      </motion.div>
    </div>
  );
}
