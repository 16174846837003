import { motion } from 'framer-motion';
import { CheckCircleIcon } from 'lucide-react';
import { useTitle } from 'react-use';
import Card from '../../components/Card';

export default function SignupSuccessPage() {
  useTitle('PPAM | Crear una nueva cuenta');

  return (
    <div className="max-w-md space-y-8 p-2 md:p-0">
      <motion.div
        className="space-y-8"
        initial={{ rotateY: 90 }}
        animate={{ rotateY: 0 }}
        transition={{ timing: 5 }}
      >
        <Card className="space-y-8 p-12">
          <div className="mb-52 space-y-8">
            <div className="space-y-12">
              <article className="flex flex-col items-center space-y-4">
                <div>
                  <CheckCircleIcon className="h-24 w-24 text-green-800" />
                </div>
                <p>Sus datos se enviaron correctamente</p>
              </article>
              <p className="text-sm text-gray-500">
                Una vez que su solicitud sea aprobada, recibirá un mensaje de confirmación en su
                correo electrónico.
              </p>
            </div>
          </div>
        </Card>
      </motion.div>
    </div>
  );
}
