import { motion } from 'framer-motion';
import { useTitle } from 'react-use';
import RequestInstalinkCard from '../../components/auth/instalink/RequestInstalinkCard';

/**
 * Main component for the /login/instalink page. Renders a form to request an
 * instalink. Expects the component to be wrapped with a react router.
 *
 * @example <InstalinkPage />
 */
export default function InstalinkPage() {
  useTitle('PPAM | Recuperar contraseña');

  return (
    <div className="max-w-md space-y-8">
      <motion.div
        className="max-w-md space-y-8"
        initial={{ rotateY: 90 }}
        animate={{ rotateY: 0 }}
        transition={{ timing: 5 }}
      >
        <RequestInstalinkCard />
      </motion.div>
    </div>
  );
}
